import React, {useState, useEffect} from 'react'
import styles from './index.module.scss'
import Clock from '../../components/Clock/Clock'
import Navbar from '../../components/Navbar'
import Tables from '../../components/Tables'
import GuestСount from '../../components/GuestСount'
import Continue from '../../components/Continue'
import Popup from '../../components/Popup'
import BusinessLunch from '../../components/BusinessLunch'
import moment from 'moment'
import FullMenu from '../../components/FullMenu'


const Index = () => {

    const [blList,
        setBlList] = useState([])
    const [fullMenuList,
        setFullMenuList] = useState([])
    const [openMenu,
        setOpenMenu] = useState(false)
    const [request,
        setRequest] = useState([])
    const [isRequest,
        setIsRequest] = useState(false)
    const [token,
        setToken] = useState(null)
    const [tables,
        setTables] = useState(null)
    const [steps,
        setSteps] = useState({
            isActive: 1,
            guestСount: 1,
            table: null
        })

    const getMenuList = () => { // получаем меню бизнес-ланча
        const date = moment().format('YYYY-MM-DD')
        try {
            const response = fetch(`https://api.menu-rest.com/api/v1/restaurant/60325/businessmenu/${date}`)
                .then(response => response.json())
                .then(data => {
                    setBlList(data.data)
                })
        } catch (error) {
            return error
        }
    }

    const getTables = () => { // получаем список столов
        try {
            const response = fetch(`https://api.menu-rest.com/api/v1/table/60325`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}` // замените username и password
                }
            })
            .then(response => response.json())
            .then(data => {
                setTables(data.data)
            })
        } catch (error) {
            return error
        }
    }

    const getMainMenuList = () => { // получаем полный меню
        try {
            const response = fetch(`https://api.menu-rest.com/api/v1/restaurant/60325/menu`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}` // замените username и password
                }
            })
            .then(response => response.json())
            .then(data => {
                setFullMenuList(data.data)
            })
        } catch (error) {
            return error
        }
    }

    async function fetchAuthToken() { // получаем токен
        const url = 'https://api.menu-rest.com/api/v1/auth/phonegetpin';
        const body = JSON.stringify({
            phone: '+78999999999'
        });
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch token');
            }
    
            const data = await response.json();
            fetchAuthToken_();
            return data.token;
        } catch (error) {
            throw error;
        }
    }

    async function fetchAuthToken_() {
        const url = 'https://api.menu-rest.com/api/v1/auth/phonelogin';
        const body = JSON.stringify({
            phone: '+78999999999',
            pin: '12345'
        });
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch token');
            }
    
            const data = await response.json();
            setToken(data.token);
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        if (!token) {
            fetchAuthToken();
        }
    }, [])

    useEffect(() => {
        getMenuList();
    }, [])

    useEffect(() => {
        if (token != null) {
            getMainMenuList();
            getTables();
        }
    }, [token])

    const handleGetRequest = (item, type) => { 
        const copy = [...request]
        if (type === 'add') {
            copy.push(item)
        } else {
            const index = copy.findIndex((element) => element.id == item.id);

            if (index !== -1) {
                copy.splice(index, 1);
            }
        }
        setRequest(copy)
    }

    const getUniqueArray = (array) => { 
        return array.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id))
    }

    const handleSubmitRequest = () => {
        setIsRequest(false)
        setRequest([])
        setSteps({
            isActive: 1,
            guestСount: 1,
            table: null
        })
    }


    const getGuestsErr = (guestСount) => {
        const guests = []
        for (let i = 0; i < guestСount; i++) {
            guests.push(`Гость ${i + 1}`)
        }
        return guests
    }

    const handleCreateGuests = () => {
        try {
            const response = fetch(`https://api.menu-rest.com/api/v1/order/60325/table/${steps.table}/create`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({guests: getGuestsErr(steps.guestСount)})
            })
            .then(response => response.json())
            .then(data => {
                handleAddFood(data.data)
            })
        } catch (error) {
            return error
        }
    }

    const handleAddFood = (data_) => {
        try {
            const response = fetch(`https://api.menu-rest.com/api/v1/order/60325/order/${data_[0].id}/${blList[0].bid}/1`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    getUniqueArray(request).map(el => {
                    return {
                        orderid: data_[0].id,
                        guest_id: 1,
                        menu_id: el.id,
                        bl_category_id: el.category,
                        amount: request.filter(item_ => item_.id === el.id).length,
                        orderrowcomment: null,
                        orderoptions: null
                }}))
            })
            .then(response => response.json())
            .then(data => {
                handleSent(data_[0].id)
            })
        } catch (error) {
            return error
        }
    }

    const handleSent = (id) => {
        try {
            const response = fetch(`https://api.menu-rest.com/api/v1/order/60325/order/${id}/sent`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            })
            .then(response => response.json())
            .then(data => {
                setSteps({...steps, isActive: steps.isActive + 1})
            })
        } catch (error) {
            return error
        }
    }

    return (
        <div className={styles.wrapper}>
            <Clock/>
            {
                openMenu
                    ? <FullMenu FullMenu={fullMenuList} closeMenu={() => setOpenMenu(false)}/>
                    : <></>
            }
            <div className={styles.content}>
                    {request.length > 0
                        ? <div className={styles.requestButton} onClick={() => handleCreateGuests()}>заказать</div>
                        : <></>}
                <Navbar/>
                <div className={styles.menu}>
                    <div className={styles.menuWrapper}>
                        <div className={styles.navbar_btn} onClick={() => setOpenMenu(true)}>меню</div>

                        {steps.isActive === 4
                            ? <Popup steps={steps} setSteps={setSteps} request={request} getUniqueArray={getUniqueArray} handleSubmitRequest={handleSubmitRequest} setIsRequest={setIsRequest} />
                            : <></>}

                        {steps.isActive === 1 && <Tables tables={tables} setSteps={setSteps} steps={steps} /> }
                        {steps.isActive === 2 && <GuestСount setSteps={setSteps} steps={steps} /> }
                        {steps.isActive === 3 && 
                        <div className={styles.back}>
                            <Continue steps={steps} setSteps={setSteps} />    
                        </div>
                        }

                        {steps.isActive === 3 && <BusinessLunch data={blList} request={request} handleGetRequest={handleGetRequest} />
}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index