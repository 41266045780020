import React from 'react'
import styles from './index.module.scss'
import Continue from '../Continue'

const Index = ({
    tables = [],
    setSteps,
    steps
}) => {

    const handleSetTable = (table) => {
        if (steps.table === table.id) {
            setSteps({
                ...steps,
                table: null
            })
        } else {
            setSteps({
                ...steps,
                table: table.id
            })
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.menuTitle}>Выберите стол
                {
                    steps.table && <Continue steps={steps} setSteps={setSteps}/>
                }
            </div>
            <div className={styles.tables}>
                {tables
                    ?.map((item) => {
                        return (
                            <div
                                className={`${styles.table} ${steps.table == item.id
                                ? styles.active
                                : ''}`}
                                key={item.id}
                                onClick={() => handleSetTable(item)}>
                                <div className={styles.name}>Стол: {item.name}</div>
                                <div className={styles.seats}>Кол-во мест: {item.seats}</div>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default Index