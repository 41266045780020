import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { TfiClose } from "react-icons/tfi";

const Index = ({FullMenu, closeMenu}) => {
    const [data, setData] = useState([])
    function groupBySectionTitleToArrays(items) {
        // Сначала создаем объект для группировки
        const grouped = items.reduce((result, item) => {
            const sectionTitle = item.section_title;
    
            // Если раздел еще не добавлен, создаем новый массив
            if (!result[sectionTitle]) {
                result[sectionTitle] = [];
            }
    
            // Добавляем текущий элемент в соответствующий раздел
            result[sectionTitle].push(item);
    
            return result;
        }, {});
    
        // Затем превращаем объект в массив массивов
        return Object.values(grouped);
    }
    
    useEffect(() => {
        if (FullMenu) {
            const groupedItems = groupBySectionTitleToArrays(FullMenu);
            setData(groupedItems);
        }
    }, [FullMenu])

    return (
    <div className={styles.bg}>
        <div className={styles.wrapper}>
            <div className={styles.close} onClick={closeMenu}><TfiClose/></div>
            <div className={styles.list}>
            {
                data?.map((el, i) => 
                    <div className={styles.container} key={i}>
                    <div className={styles.section_title}>{el[0].section_title}</div>
                    <div className={styles.itemBlock}>
                        {
                            el.map(_el =>
                                <div className={styles.item} key={_el.id}>
                                    <div className={styles.item_inner}>
                                        <img src={_el.image[0].Url} className={styles.image}/>
                                        <div className={styles.itemAbout}>
                                            <div className={styles.title}>{_el.title_ru}</div>
                                            <div className={styles.composition}>{_el.composition}</div>
                                        </div>
                                    </div>
                                    <div className={styles.itemPrice}>{_el.price.slice(0, -3)} ₽</div>
                                </div>
                            )
                        }
                    </div>
                    </div>
                )
            }
            </div>
        </div>
    </div>
  )
}

export default Index